import _ from 'lodash'
import type { placeNextBetType, transactionTypes } from '@/utils/fishtrap/types'
import { v4 as uuid_v4 } from 'uuid'
import { uploadFile } from '@/utils/api/upload'
import { BehaviorSubject } from 'rxjs'
import { domToBlob } from 'modern-screenshot'

export const transactionInitialState: transactionTypes = {
  betAmount: 0,
  betIndex: 0,
  uuid: uuid_v4()
}

export const playNextInitialState: placeNextBetType = {
  betAmount: 0,
  betIndex: 0,
  uuid: uuid_v4()
}

export const randomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min)) + min
}

export const makeMoneyFormat = (nm: string | number, currency: string, locale?: string) => {
  const money = Number(nm)

  return new Intl.NumberFormat(locale ?? 'tr-TR', {
    style: 'currency',
    currency: currency || 'TRY',
    maximumFractionDigits: 2
  }).format(money)
}

export const makeOnlyMoneyFormat = (nm: string | number, locale?: string) => {
  const money = Number(nm)

  return new Intl.NumberFormat(locale ?? 'tr-TR', {
    maximumFractionDigits: 2
  }).format(money)
}

export const getCurrencySymbol = (currency: string, locale?: string) => {
  return (0)
    .toLocaleString(locale ?? 'tr-TR', {
      style: 'currency',
      currency: currency || 'TRY',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim()
}

export const convertToDot = (value: string | undefined): string => {
  if (!value) return ''
  value = value.replace(/[,.]+/g, '.').replace(/[^0-9.]/g, '')

  if (!value.startsWith('0.') && value !== '0') {
    value = value.replace(/^0+/, '')
  }

  const parts = value.split('.')
  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, 7)
  }

  return parts.join('.')
}

export const handleKeydown = (
  fieldType: string,
  event: any,
  emit: (event: 'action:keydown', value: KeyboardEvent) => void
) => {
  if (fieldType === 'number') {
    if (
      [46, 8, 9, 27, 13, 110, 188, 190].includes(event.keyCode) ||
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      if (
        (event.keyCode === 110 || event.keyCode === 190 || event.keyCode === 188) &&
        (event?.target?.value.includes('.') || event?.target?.value.includes(','))
      ) {
        event.preventDefault()
      }
      return
    }
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault()
    }
  }
  return emit('action:keydown', event)
}

export const takeScreenshot = async (elem?: string, forge?: boolean) => {
  const mainEl = document.querySelector(elem || '.main')
  const wasChatBoxOpened = mainEl?.classList?.contains('chat-box-opened')
  if (wasChatBoxOpened) {
    mainEl?.classList?.remove('chat-box-opened')
  }

  const el = !forge ? document.getElementById('app') : elem ? document.querySelector(elem) : null

  if (el) {
    const blob = await domToBlob(el, {
      width: el.getBoundingClientRect().width,
      height: el.getBoundingClientRect().height,
      features: {
        removeControlCharacter: false
      }
    })

    if (blob) {
      // const objectURL = URL.createObjectURL(blob)
      // window.open(objectURL, '_blank')

      wasChatBoxOpened && mainEl?.classList?.add('chat-box-opened')
      return blob
    } else {
      return new Error('Failed to convert canvas to Blob')
    }
  }
}

export const uploadScreenshot = async (
  fileName: string,
  token: string | null,
  elem?: string,
  forgeSpesificElement?: boolean,
  cb?: Function
) => {
  try {
    const screenshotBlob = await takeScreenshot(elem, forgeSpesificElement)

    if (fileName && token) {
      if (cb !== undefined) {
        cb()
      }

      await uploadFile(`transactions/${fileName}.png`, screenshotBlob, token)
    }
  } catch (error) {
    console.error('Error uploading screenshot: ', error)
  }
}

export const playPauseSound = (sound: Howl, cond: boolean) => {
  if (cond) {
    if (sound != null) {
      sound.stop()
    }

    sound.play()
  } else {
    sound.pause()
  }
}

export const chunkRight = (arr: any[], size: number) => {
  const rm = arr.length % size

  return rm ? [arr.slice(0, rm), ..._.chunk(arr.slice(rm), size)] : _.chunk(arr, size)
}

export const generatePlinkoRates = (baseRates: number[], desiredRow: number) => {
  const totalRates = desiredRow + 1
  const filteredBaseRates = baseRates.map((rate) => rate || 0)

  if (totalRates === filteredBaseRates.length) {
    return filteredBaseRates
  }
  const growthPatterns = {
    outer: 1.8,
    inner: 0.9
  }

  const newRates = []
  const baseMiddleIndex = Math.floor(filteredBaseRates.length / 2)

  for (let i = 0; i < baseMiddleIndex; i++) {
    const scale = i === 0 || i === baseMiddleIndex - 1 ? growthPatterns.outer : growthPatterns.inner
    const baseIndex = Math.min(i, baseMiddleIndex - 1)
    const newRate = filteredBaseRates[baseIndex] * Math.pow(scale, Math.abs(desiredRow - 8) / 2)
    newRates.push(Math.max(0.1, parseFloat(newRate.toFixed(1))))
  }

  newRates.sort((a, b) => b - a)

  const middleRatesCount = totalRates - 2 * newRates.length

  if (totalRates > 10) {
    for (let i = 0; i < middleRatesCount; i++) {
      let adjustment = 0
      if (i === 0 || i === middleRatesCount - 1) {
        adjustment = 0.4
      } else if (i === 1 || i === middleRatesCount - 2) {
        adjustment = 0.3
      } else if (i === 2 || i === middleRatesCount - 3) {
        adjustment = 0.1
      }
      newRates.push(parseFloat((filteredBaseRates[baseMiddleIndex] + adjustment).toFixed(1)))
    }
  } else {
    for (let i = 0; i < middleRatesCount; i++) {
      newRates.push(parseFloat(filteredBaseRates[baseMiddleIndex].toFixed(1)))
    }
  }

  const mirroredPart = newRates.slice(0, newRates.length - middleRatesCount).reverse()
  const completeRates = newRates.concat(mirroredPart)

  return completeRates.slice(0, totalRates)
}

export const checkImageUrl = (
  url: string | undefined,
  callback: (isValid: boolean) => void
): void => {
  if (url == null) {
    callback(false)
    return
  }

  const img = new Image()
  img.onload = () => callback(true)
  img.onerror = () => callback(false)
  img.src = url
}

const loadingStatus = new BehaviorSubject(false)
export const LoadingStatusHelper = {
  status$: loadingStatus.asObservable(),
  set: (status: boolean) => {
    loadingStatus.next(status)
  }
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
